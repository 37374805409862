.toggleButton {
  width: 40px;
  height: 40px;
  &.active {
    color: darkblue;
  }
}

.search {
  position: relative;
  display: inline-block;
  z-index: 10;
}

.optionsContainer {
  background-color: white;
  margin-right: 2rem;
  padding: 1rem;
}

.optionsList {
  margin: 0;
  padding: 0;
  list-style-type: none;
  li {
    display: inline-block;
    padding: 0.15rem;
  }
}

.clearAll {
  margin-top: 0.5rem;
  margin-bottom: -0.5rem;
  float: right;
  color: #555;
  background-color: white;
  &:hover {
    color: darkblue; // TODO better color
  }
}

.clearfix {
  clear: both;
}

.option {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #eee;
  border-radius: 2em;
  position: relative;
  height: 2em;
  &:hover {
    background-color: lightblue;
  }
  label {
    padding-left: 0.25em;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: inline-flex;
    cursor: pointer;
  }
  button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 1.5em;
    height: 1.5em;
    border-radius: 1.5em;
    margin: -0.2em;
    margin-left: 0;
    margin-right: 0.25em;
    &:hover {
      background-color: white;
    }
  }
}
