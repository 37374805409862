@import './colors.scss';

.image {
  width: 100%;
}

.container {
  background-color: white;
  padding: 1.5rem;
}

.close {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
}

.link {
  color: #69c;
}

.infoSection {
  & > div {
    margin: 1rem 0;
    display: flex;
    align-items: center;
    justify-content: left;

    & > :first-child {
      margin-right: 0.5rem;
    }
  }
}

.serviceButton {
  text-align: left;
  &:hover {
    background-color: #eee;
  }
  padding: 0.2rem;
  margin-left: -0.2rem;
}

.openingHours {
  display: grid;
  grid-template-columns: auto auto auto;

  .selfService {
    color: $helmet-orange;
  }
}
