@import './breakpoints.scss';

.timeline {
  position: fixed;
  bottom: 0;
  z-index: 1000;
  width: 100vw;
}

.mainMenu {
  position: fixed;
  top: 20px;
  left: 50px;
  z-index: 1000;
  display: flex;
  align-items: middle;

  .menuItem {
    &:hover {
      background-color: #fffa;
    }
    height: 40px;
    width: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}

.info {
  @include for-phone-only {
    width: 100vw;
  }
  @include for-tablet-portrait-up {
    width: 400px;
  }
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  overflow: auto;

  // at the bottom, include padding that will cover the timeline, so we can see data below
  padding-bottom: calc(50px + 0.75rem);
}
