.languageSelect {
  padding: 0;
  display: block;
  font-size: 20px;
  font-weight: 600;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-transform: uppercase;
  height: 40px;
  line-height: 40px;
  width: 40px;
  background: none;
  border: none;
  cursor: pointer;
  text-align: center;
}
