.list {
  position: absolute;
  background-color: #fff;
  margin: 0;
  padding: 0;
  .item {
    list-style-type: none;
    margin: 0;
    padding: 0.4em 1em;
    &:hover {
      background-color: #eee;
    }
    cursor: pointer;
  }
}

.button {
  width: 40px;
  height: 40px;
}

.searchLabel {
  &.active {
    background-color: white;
    border-radius: 3px;
    position: relative;
  }
  &:focus-within {
    outline: 2px solid black;
  }
  display: flex;
  align-items: center;
  input {
    border: none;
    &:focus {
      border: none;
      outline: none;
    }
  }
}
